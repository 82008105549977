import { FC, SVGProps } from 'react';

export const Louise: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 103 25">
      <path
        fill="#43655B"
        d="M4.8 2.2c.2 2.8 0 5.6 0 8.5-.2 5.2-.3 5.2-1 10.3v-.3c0-.4-.4-.6-.7-.5l1-.1c5.8-.6 1.8-.3 7.7-.8 4.3-.4 9-1.1 13.3-1.8h.3l.2 4c-1.3-.4-2-1-2.7-2-1.7-2.6-2.2-6.5-.4-9.1 1.6-2.5 4.6-3.7 7-2.2 5 2.5 6.6 12.5 1 15.3-1.7 1-4.2.4-5.2-1.7-.5-.8-.7-2.2 0-3.1.3-.5 1-1 1.4-.6 2.7 2 5.5.3 7.7-1.7 1.2-1 2.5-2 3.8-2.8l1.8-1.1c.6-.5 1-1.1 1.2-1.8l1.6.4c-.7 2.7-.1 6 .8 8.4.9 1.6 3-.2 4-1.2 1.5-1.5 3-3.7 4.1-5.8l.2-.3c.2-.4 1.1-1.5 2.2-.8.7.2 1 2 .9 1.8v.2c0 1.8-.6 4.7.2 6v.2h.2c1 0 2-.7 2.8-1.5 1.4-1.5 2.3-3.6 4-5 .6-.3 1.4-.3 2-.3h1.6c.4 0 .6.5.6.9-.1.5 0 1.1.3 1.6l1 1.5c.6.9.8 2.6 1.9 2.6.8-.1 1.7-.9 2.4-1.5 1.5-1.3 3-3 4.2-4.7 1.2-1.5 2.4-3 3.2-4.8H82a13 13 0 0 0 0 6.3c.3 2 1.5 3.2 1.7 5.4.3 2.8-1.9 5.6-4.2 3.8-1.3-1-1.4-3.1-.5-4.5 1.9-3.4 5.3-3.4 8.2-4l2.4-.5c2-.6 7.4-1.4 6.6-4.7v.1l.3.3h.1c-.8.5-.9 1.9-.9 3v2.9c.2.5.4.8 1 1.1 1.7.7 3.7.4 5.3-.4.4-.2.9 0 1 .5.5 1.2-1.4 2.4-2 2.8-2.7 1.7-6.8 1.6-8.3-2.1-1.1-3.3.3-7.7 3-9.3.6-.5 1.7-.5 2 .5 1.8 6.2-8.4 9-11.6 11.5l-2.6 1.8c-.2.2-.3.3-.4.1l-4-3.8c.4.5.8-.3 1-.9.3-1 .3-2.2.2-3.3-.2-2.3-.3-4.5-.7-6.8-.3-1.7 1.9-2.4 2.3-.6.4 1.8-.6 3.5-1.3 5-2 3.7-4.6 7.2-7.8 9.7-1.1 1-2.8 1.6-4.2 1.1-2-.7-3.5-3-3.8-5.5-.1-1.4.4-2.7 0-4l.8.9c-.1.8.3 2.2-.4 2.9a13 13 0 0 1-8.1 5.5c-3.4.8-5.1-1.6-5.3-5.4v-4.6c0-.4.1 1.5 1 1.7.5.4 1 .3 1.5 0 .6-.5.6-1 .6-.7C53 18 51 21.9 47.5 24c-4.1 2.7-8.7-.9-8-6.6.5-2.4 1.4-4.5 1.7-6.8.1-.6.5-.9 1-.8.4.1.7.7.6 1.2-.8 4.6-3.2 7.4-6.7 9a9.6 9.6 0 0 1-10.2-.3h.7s0 .1 0 0v.1l.3.4c.8.5 1.6 0 2.5-.3 1.4-.6 1.8-2.4 1.6-4.3-.1-2-1-4-2.5-4.9-1-.7-2-.6-3 0-2.7 1.4-1.3 4.8.5 6.4l.2.2.2 4-2 .4c-4.2.3-5.8 1-9.9 1.2a107.5 107.5 0 0 0-10 1.5l-.8.2c-.7.1-1.5 0-2.1-.5-1.2-.8-1.8-2.5-1.5-4L.5 12V2.3c.4-3 4-3 4.3 0v-.1Z"
      />
      <path
        fill="#43655B"
        d="M65.2 7.2v1c0 .3 0 .4.2.1-.1 2.3-3.3 2.3-3.4 0 0-.4.2-.7.5-1 .1-.2.2-.2.2 0 0-1.7 2.4-1.7 2.5 0Z"
      />
    </svg>
  );
};
