import { FC, SVGProps } from 'react';

export const Hello: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 89 39">
      <path
        fill="#43655B"
        d="M4.8 39a2.7 2.7 0 0 1-2.6-2.7C2.2 21 .6 3 .5 2.9.4 1.5 1.5.2 3 0 4.4 0 5.7 1 6 2.4c0 .2 1.7 18.4 1.6 34 0 1.4-1.2 2.6-2.7 2.6Z"
      />
      <path
        fill="#43655B"
        d="M1 17.9c4.8-2.5 9.4-5.9 12.7-10.2 1-1.5 1.9-3 2.5-4.6a1.4 1.4 0 0 1 2.8.7 85.8 85.8 0 0 0 .7 28.2l.4 1c1.3 3.3-3.4 5.3-4.9 2l-.8-2.4c-.8-4.6-.6-9.3-.4-14a92 92 0 0 1 2.2-15.4l2.7.8a26.2 26.2 0 0 1-6.6 10 46.3 46.3 0 0 1-9.6 7.1c-.9.5-2 .2-2.5-.7-.5-1 0-2 .9-2.5Z"
      />
      <path
        fill="#43655B"
        d="M22 26.2c4.5-1.5 11.3-5.1 10.3-10.8 0-.3-.3-.5-.6-.6-1.2 0-2 1.2-2.6 2.2-1.9 3.4-2.3 7.7-2.4 11.6 0 1-.1 2.5.7 3.3.6.4 1.4.5 2.1.5 2.4-.5 4.3-2.4 6-4.2a44 44 0 0 0 9.3-22.5l3 .5c-1 6.6-2 13.5-1.5 20.2.2 1.8.6 4 1.5 5.5.8 1.2.5 2.9-.8 3.7-4.7 2.5-5.4-6.1-5.4-9a92 92 0 0 1 3.3-21c.3-2 3.2-1.5 3 .5a43 43 0 0 1-9.4 24.8c-2.3 2.6-5.4 5.4-9.2 5.6-3.4 0-6.4-2.3-6.5-5.9 0-3.4.5-6.8 1.6-10.1 1-3.3 3.6-9 7.9-8.3 2.6.6 3 3.7 2.6 6-.7 3.8-3.8 6.6-7 8.7-1.5 1-3 2-4.7 2.7-2.4 1-3.7-2.7-1.2-3.4ZM55.3 6a57.6 57.6 0 0 0-.2 19.8c.4 2 1 4 1.8 6 .7 1.3 0 3-1.3 3.5-1.4.7-3 0-3.6-1.5a38.3 38.3 0 0 1-2-14.5 69.6 69.6 0 0 1 2-14 1.7 1.7 0 0 1 3.3.7ZM64.5 22.4c-.4 2.3-.8 6.5 1.7 7.7 2.9 1.1 5.2-1.2 6-4 .7-2 1-4.7-.3-6.2-.4-.3-.5-.4-1-.3-1.5.4-2.7 1.6-3.9 2.8a33 33 0 0 0-3.5 4.4 1.8 1.8 0 0 1-3-2c1.1-1.8 2.3-3.4 3.7-5 2.5-3 7-6.2 10.4-3 3 2.7 2.9 7.3 1.9 10.7-1 3.7-4 7.2-8 7.6-8.3.8-10.5-7.1-9.2-13.8.8-3.6 6-2.5 5.2 1Z"
      />
      <path stroke="#43655B" stroke-linecap="round" stroke-width="3" d="M86.8 29.5c0 2.5-1.5 4.2-4.3 4.2" />
    </svg>
  );
};
