import { ComponentPropsWithoutRef, ElementType, FC, ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Hello } from './handwriting/Hello';
import { Louise } from './handwriting/Louise';
import { Signature } from './handwriting/Signature';
import { cn } from './utils/cn';

import { Turnstile } from '@marsidev/react-turnstile';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { FormState, useForm } from 'react-hook-form';
const profileSmall = '/profile-small.jpg';
const profile = '/profile.jpg';
const sofa = '/sofa.jpg';
const space = '/space.mp4';

interface ContactFormValues {
  name?: string;
  email?: string;
  phonenumber?: string;
  text?: string;
  'cf-turnstile-response'?: string;
}

const FormError: FC<{ id: keyof ContactFormValues; formState: FormState<ContactFormValues>; message?: string }> = ({
  id,
  formState,
  message
}) => {
  return formState.errors[id] && <p className="text-red-600">{message ? message : formState.errors[id]?.message}</p>;
};

const Form = () => {
  const { handleSubmit, register, setValue, formState } = useForm<ContactFormValues>();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<true | { message: string }>();

  const onSubmit = async (data: ContactFormValues) => {
    try {
      setResult(undefined);
      setLoading(true);
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...data
        })
      });

      if (!response.ok) {
        const body = await response.json();
        setResult({ message: body.error });
        return;
      }

      setLoading(false);
      setResult(true);
    } catch (e) {
      setResult({ message: 'Something went wrong. Please try again later.' });
    }
  };

  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <FormError id="cf-turnstile-response" formState={formState} />
        <Turnstile
          options={{ appearance: 'interaction-only' }}
          {...register('cf-turnstile-response', {
            required: `Sorry, your browser can't be verified and you might be a robot. Please drop me an email if this is wrong!`
          })}
          onSuccess={token => setValue('cf-turnstile-response', token)}
          siteKey="0x4AAAAAAALkBZ20xcGSX1lH"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <div className="flex flex-col gap-1">
          <input
            className="w-full rounded-md"
            {...register('name', { required: 'I need your name to continue.' })}
            type="text"
            placeholder="Name"
          />
          <FormError id="name" formState={formState} />
        </div>
        <div className="flex flex-col gap-1">
          <input
            className="w-full rounded-md"
            {...register('email', {
              required: true,
              pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
            })}
            type="text"
            placeholder="Email"
          />
          <FormError id="email" formState={formState} message="A valid email is required." />
        </div>
        <div className="flex flex-col gap-1">
          <input
            className="w-full rounded-md"
            {...register('phonenumber')}
            type="text"
            placeholder="Phone number (optional)"
          />
          <FormError id="phonenumber" formState={formState} />
        </div>
        <div className="md:col-span-3 flex flex-col gap-1">
          <textarea className="w-full rounded-md" {...register('text')} placeholder="Type your message here" rows={6} />
          <FormError id="text" formState={formState} />
        </div>
      </div>
      {result === true ? (
        <p className="text-green-600">Thanks for getting in touch! I'll get back to you as soon as I can.</p>
      ) : result?.message ? (
        <p className="text-red-600">{result.message}</p>
      ) : null}
      <button
        disabled={loading}
        className="border border-primary-700 text-white font-medium bg-primary-600 hover:bg-primary-700 p-2 mt-3 rounded-md"
        type="submit"
      >
        {loading ? 'Loading...' : 'Submit'}
      </button>
    </form>
  );
};

const Video = () => {
  const [ref, entry] = useIntersectionObserver<HTMLVideoElement>({
    threshold: 0
  });

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (entry?.isIntersecting && !load) {
      setLoad(true);
    }
  }, [entry]);

  return (
    <video
      ref={ref}
      poster="/space-poster.jpg"
      src={load ? space : undefined}
      autoPlay
      loop
      muted
      playsInline
      className="object-cover object-center sm:w-2/3 overflow-clip rounded-2xl border-[6px] border-white"
    ></video>
  );
};

const Map = () => {
  const [ref, entry] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0
  });

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (entry?.isIntersecting && !load) {
      setLoad(true);
    }
  }, [entry]);

  return (
    <div
      ref={ref}
      className="rounded-2xl bg-white sm:w-[calc(33%-1.25rem)] object-cover object-center flex-1 border-[6px] border-white"
    >
      {load && (
        <iframe
          className="w-full h-full"
          loading="lazy"
          src="https://www.google.com/maps/embed/v1/place?q=Beeston,+Nottingham,+UK&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        ></iframe>
      )}
    </div>
  );
};

interface BoundedProps<T extends ElementType> {
  as?: T;
  className?: string;
  children?: ReactNode;
}

function Bounded<T extends ElementType = 'div'>({
  as,
  className,
  ...props
}: BoundedProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof BoundedProps<T>>) {
  const Component = as || 'div';
  return <Component className={cn('px-6 sm:px-8', className, 'w-full max-w-7xl mx-auto')} {...props} />;
}

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <main className="font-primary bg-primary-50 w-screen h-screen py-4 sm:py-8">
              <Bounded as="header">
                <div className="pb-4 flex border-b border-primary-200 justify-center items-center sm:justify-between text-primary-600">
                  <div className="flex flex-col gap-1 items-center sm:items-start">
                    <Signature className="w-full max-w-md" />
                    <h2 className="sm:text-lg font-medium">Counsellor & Psychotherapist</h2>
                  </div>
                  <div className="flex-col text-right hidden sm:flex">
                    <a href="mailto:hello@louisewilliams.co.uk">hello@louisewilliams.co.uk</a>
                    <a href="tel:0115 857 1311" className="font-semibold">
                      0115 857 1311
                    </a>
                  </div>
                </div>
              </Bounded>
              <Bounded as="nav">
                <div className="hidden sm:flex items-center py-4 gap-4 text-primary-600">
                  <a href="#me">Me</a>
                  <a href="#mypractice">My practice</a>
                  <a href="#myservices">Services</a>
                </div>
                <div className="flex sm:hidden justify-center items-center py-4 gap-4 text-primary-600">
                  <a href="#me">Me</a>
                  <a href="#mypractice">My practice</a>
                  <a href="#myservices">Services</a>
                </div>
              </Bounded>
              <Outlet />
            </main>
          }
        >
          <Route
            path="/"
            element={
              <>
                <section id="me" className="bg-primary-200">
                  <Bounded className="flex py-12 gap-12">
                    <img
                      loading="lazy"
                      className="hidden md:inline border-8 object-cover w-1/4 border-white rounded-lg"
                      src={profile}
                    />
                    <div className="flex items-center text-primary-700">
                      <div className="text-primary-700 flex flex-col items-start gap-5">
                        <div className="flex justify-between items-center w-full">
                          <div className="flex flex-col gap-3">
                            <Hello className="h-[3rem]" />
                            <p>I’m Louise,</p>
                          </div>
                          <img
                            src={profileSmall}
                            className="inline md:hidden object-cover rounded-full border-4 border-white w-[6rem] h-[6rem]"
                          />
                        </div>
                        <p>
                          At the heart of my practice is warmth and mutual respect. I am patient and accepting and I’ve
                          had many years of experience listening to others. I’m a qualified therapist and have undergone
                          robust training and personal therapy. I am registered with the BACP (British Association of
                          Counsellors and Psychotherapists).
                        </p>
                        <p>
                          I would be very glad to hear from you if you would like a conversation or have questions about
                          therapy. Whether you get in touch now or at another point down the line, I wish you well,
                          considering therapy is a big step and you’ve taken the first one.
                        </p>
                        <div className="flex items-center gap-5">
                          <div className="flex flex-col items-start gap-3">
                            <Louise className="h-[2rem]" />
                            <p className="text-sm">(she/her)</p>
                            <div className="flex-col flex sm:hidden">
                              <a href="mailto:hello@louisewilliams.co.uk">hello@louisewilliams.co.uk</a>
                              <a href="tel:0115 857 1311" className="font-semibold">
                                0115 857 1311
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Bounded>
                </section>
                <section>
                  <Bounded className="flex py-16 gap-12">
                    <div className="flex flex-col gap-8 md:flex-row text-primary-700">
                      <div className="text-primary-600 flex flex-col items-start gap-5">
                        <h2 className="text-2xl font-semibold">Curious about Therapy?</h2>
                        <p>I don’t know yet what it’s taken you to get to this point, but I’m glad you’re here.</p>
                        <p>
                          People seek therapy for all kinds of reasons, and there can be natural anxiety or reluctance
                          to begin. There is no right or wrong way to start and you will know if you are ready to
                          explore counselling.
                        </p>
                        <p>
                          Some of the things I’ve heard people say when considering therapy are, is that it might feel
                          weird talking to a therapist or they can’t see the point as it was too long ago in the past. I
                          spent a long time carrying things on my own and feeling this way too. Finding a private,
                          confidential space and building a relationship with my therapist was transformational, and I
                          want to provide this too.
                        </p>
                      </div>
                      <img loading="lazy" className="object-cover w-full md:w-1/3 rounded-lg" src={sofa} />
                    </div>
                  </Bounded>
                </section>
                <section id="mypractice" className="bg-primary-200">
                  <Bounded className="flex py-16  items-center text-primary-700">
                    <div className="text-primary-700 flex flex-col items-start gap-5">
                      <h2 className="text-2xl font-semibold">My practice</h2>
                      <p>
                        I am a Humanistic and Integrative counsellor sometimes called a psychotherapist. The humanistic
                        bit means you come first, you are a whole person, made up of many parts and there is no one like
                        you. You are unique and valuable.
                      </p>
                      <p>
                        I work relationally, experiencing whatever feelings, sensations, memories, thoughts and beliefs
                        emerge for you. The integrative bit means that I draw on lots of theories to help make sense of
                        difficult emotions in the best way possible. We can talk more about this when we meet.
                      </p>
                      <p>
                        I work to BACP ethical standards, and in line with their requirements, completed clinical
                        obligations, and had long-term therapy myself. I qualified to a high standard with an Honorary
                        Bachelor of Science degree in Counselling and Psychotherapy.
                      </p>
                      <p>
                        As I work online I am a member of ACTO (Association for Counselling and Therapy online) I am an
                        Associate Therapist and Assessor with Nottingham Counselling Service and a member of the
                        Sherwood Psychotherapy Training Institute. I have an enhanced DBS and liability insurance with
                        Holistic Insurance.
                      </p>
                    </div>
                  </Bounded>
                </section>
                <section id="myservices" className="bg-primary-700 py-16 text-white">
                  <Bounded className="flex flex-col gap-5">
                    <h2 className="text-2xl font-semibold">Services</h2>
                    <p>
                      I offer an initial online 30-minute assessment for which there is no charge. We can use this time
                      to start to get to know each other, discuss your hopes and goals for therapy, and decide if we can
                      work together. You may choose to go to someone else, or I may feel that I don’t have the right
                      skills for you. If this is the case I will do my best to refer you to someone who will be more
                      able to help you.
                    </p>
                    <div className="flex flex-col sm:flex-row gap-5">
                      <Video />
                      <Map />
                    </div>
                    <p>
                      Sessions last for 50 mins and cost £50 from my practice in Beeston. This space is accessed by
                      stairs. If you require a ground-floor room we can meet in Nottingham City Centre and I am happy to
                      work online. Depending on your goals we can work to a time frame or have an open-ended plan which
                      continues for as long as you need. We’ll discuss this when we first meet and throughout as we
                      continue to work together.
                    </p>
                    <p>
                      Payment is to be made by bank transfer prior to the session starting and I require at least 48
                      hours notice of cancellation, otherwise, you will be expected to pay in full. Some limited
                      concessions are available to those on a low income. Please ask for details.
                    </p>
                    <p>
                      Once we have decided to work together, I will ask you to sign a working agreement that confirms
                      where and when your sessions will take place. It gives information on the ethical framework and
                      details the limitations of confidentiality.
                    </p>
                    <div>
                      <p>I have training or experience in working with:</p>
                      <p>
                        Anxiety, neurodiversity, autism, ADHD, executive function issues, childhood, emotional and
                        spiritual abuse, grief, loss and bereavement, trauma and post-trauma implications, dissociation,
                        low self-esteem, loneliness, personality disorders, attachment issues, gender diverse clients,
                        addiction, menopause, depression, family dysfunction, abandonment and inner child work, high
                        sensitivity. developmental disorders, nervous system regulation, life transitions, academic
                        support, and burnout.
                      </p>
                    </div>
                  </Bounded>
                </section>
                <section>
                  <Bounded className="py-16 gap-12">
                    <div className="flex flex-col items-start gap-5 text-primary-700">
                      <h2 className="text-2xl font-semibold">I'd love to hear from you</h2>
                      <Form />
                    </div>
                  </Bounded>
                </section>
                <section className="bg-white">
                  <Bounded className="py-16 gap-12">
                    <div className="flex justify-between items-start gap-5">
                      <a href="https://www.acto.org.uk/" target="_blank">
                        <img className="h-36 object-contain" loading="lazy" src="/acto.png" />
                      </a>
                      <a href="https://www.holisticinsurance.co.uk" target="_blank">
                        <img className="h-36 object-contain" loading="lazy" src="/holistic.png" />
                      </a>
                      <a href="https://safespacealliance.com/" target="_blank">
                        <img className="h-36 object-contain" loading="lazy" src="/safespace.webp" />
                      </a>
                      <a href="https://www.spti.net/" target="_blank">
                        <img className="h-36 object-contain" loading="lazy" src="/sherwood.jpg" />
                      </a>
                      <a href="https://www.bacp.co.uk/" target="_blank">
                        <img className="h-36 object-contain" loading="lazy" src="/bacp.png" />
                      </a>
                    </div>
                  </Bounded>
                </section>
              </>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
